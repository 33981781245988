@use "./../_variables"
@import ./../tools

:root
    // --content-width: 90%
    --content-width: 1600px
    --change-point: 1200px
    --dialog-max: 1000px
    --border-style-color: var(--border-color)
    --subtle-shadow: 1px 1px 2px 1px #2d332b1f
    --subtle-text-shadow: #2d332b1c 2px 1px 2px

#landing-page
    @media (min-width: 1200px)
        max-width: var(--content-width)
        margin: auto
        box-shadow: 0px 1px 19px 5px #5b645934
    .p-datatable
        margin: auto
    .p-column-title
        @include resp-font(.45vw)
        font-weight: 700
        font-family: var(--primary-header-font)

    .data-table-text
        @include resp-font(.5vw, .6rem)
        font-weight: 400
        font-family: var(--primary-text-font)

.main-header
    @include resp-font(.5vw, 1.15rem)
    font-family: var(--primary-header-font)
    font-weight: 700
#email
    margin-top: auto
    height: auto

.price-guide-button
    padding: 3px

.p-button
    box-shadow: var(--subtle-shadow)

.subtle-shadow-text
    text-shadow: var(--subtle-text-shadow)

.loader
    position: fixed
    z-index: 9999
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: rgb(3,59,11)
    background: radial-gradient(circle, rgba(46, 57, 94, 0.866) 0%, rgba(59, 71, 97, 0.46) 49%, rgba(0,0,0,0.14889705882352944) 100%)

.message
    position: fixed
    z-index: 9999
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: white
    text-align: center

#price-table
    margin: 5px 0
    border: 2px solid var(--border-style-color)
    .job-text-cell
        @include resp-font(.45vw, .65rem)
        font-weight: 600
        font-family: var(--primary-text-font)
        margin: 0
        padding: 0 0 0 2%
    .job-text
        font-family: var(--primary-header-font)
        @include resp-font(.25vw, .85rem)
        margin: 0
        padding: 0
        font-weight: 600
        border-right: 2px solid var(--border-style-color)
    .job-info-text
        font-weight: 400
        font-family: var(--primary-text-font)
        @include resp-font(.25vw, .75rem)
        // font-weight: 300
        // font-size: .85rem
    .row-legend
        @include resp-font(.25vw, .75rem)
        font-weight: 600
        font-family: var(--primary-header-font)
        // font-weight: 600
        // font-size: .85rem
        align-items: center
        padding: 1% 0
        div
            padding: 2%
    .cell-info
        font-weight: 300
        font-size: .85rem

    .price-table-row:nth-child(even)
        background: var(--surface-200)
        filter: brightness(110%)
    .price-table-row:nth-child(odd)
        background: var(--surface-300)
        filter: brightness(110%)

    .price-table-row
        // max-height: 10rem
        margin: auto
        border-bottom: 2px solid var(--border-style-color)
        .price-row-cell
            padding: .35rem 0 0 .5rem
            @include resp-font(.45vw, .65rem)
            font-family: var(--primary-text-font)
        .value-cell
            .upper-cell
                border-bottom: 1px solid var(--border-style-color)

    .price-table-row:last-child
        border-bottom: 0

#price-table-header
    @include resp-font(.2vw, .95rem)
    font-weight: 700
    font-family: var(--primary-header-font)
    margin: auto
    font-weight: 600
    color: var(--brand-text-color-light)
    background: var(--primary-darken)

#ncto
    margin: auto

    @media (min-width: 1200px)
        max-width: var(--content-width)
        box-shadow: 0px 1px 19px 5px #5b645934

    .ncto-content-header
        @include resp-font(.5vw, 1.15rem)
        font-weight: 700
        font-family: var(--primary-header-font)
    .ncto-panel-title
        @include resp-font(.25vw, 1rem)
        font-weight: 700
        font-family: var(--primary-header-font)
        // font-family: 'Palanquin Dark', sans-serif

    .p-panel
        h6
            @include resp-font(.25vw, .85rem)
            font-family: var(--primary-text-font)

    .p-button-label
        @include resp-font(.45vw, .65rem)
        font-family: var(--primary-text-font)

    .p-inputtext
        @include resp-font(.45vw, .65rem)
        font-family: var(--primary-text-font)

    .p-panel-header
        background: var(--surface-400)
        background: linear-gradient(45deg, var(--surface-500) 0%, var(--surface-400) 100%)
        // background: linear-gradient(45deg, var(--surface-500) 0%, #66da55 100%)
        // background: linear-gradient(45deg, #4ED03B 0%, hsla(105, 72%, 31%, 1) 100%)
    .p-panel-title
        color: var(--text-color)
    // .p-highlight
    //     background: var(--primary-darken)
        // background: linear-gradient(141deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 78.77%, var(--gradient-color-3) 100%)
    .p-inputwrapper-focus
        // background: var(--primary-bg)
        // background: var(--primary-darken)
        background: var(--surface-400)
        // text-color: #fff
        // background: linear-gradient(141deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 100% )

    .p-autocomplete
        // background: var(--primary-darken)
        &.p-button
            background: var(--primary-darken)

    .p-autocomplete-item
        max-height: 25px

    .row-item:nth-child(even)
        background: var(--surface-200)
    .row-item:nth-child(odd)
        background: var(--surface-300)
    .job-select-card
        background: var(--surface-100)

#job-selection
    #calendar
        width: 17%
    #estimate
        width: 16%
    .job-name
        line-height: 1

.job-field-label
    @include resp-font(.25vw, .75rem)
    font-family: var(--primary-text-font)

.job-field-unselected
    background-color: #e6e6e6d0
    margin: 3px 0
    padding: .3rem 5px 0
    min-height: 2.6rem
    border: 3px solid #d3d2d2
    border-radius: 3px
    @include resp-font(.5vw, .5rem)
    font-family: var(--primary-text-font)
    cursor: not-allowed
    color: #838383
    .pi
        font-size: 16px !important
    .sim-button
        float: right
        margin: auto
        @include resp-font(.5vw, .7rem)
    .job-field-unselected-label
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    &:hover
        background-color: #f5f5f5d0

.job-field-selected
    .p-inputtext
        font-size: calc(.25vw + .75rem) !important
        font-family: var(--primary-text-font) !important

.job-select-column-names
    @include resp-font(.15vw, 1rem)
    font-family: var(--primary-header-font)
    font-weight: 700

.action-buttons
    margin: 0 3px
    &.p-button-icon-only
        padding: 0.2rem 0

.mx-width-pane
    max-width: 1000px

.small-info-text
    font-weight: 400
    @include resp-font(.25rem, .75rem)

.clear-borders
    border: none

.field-error-display
    border-radius: 3px
    padding: 3px 7px 4px 10px
    // background-color: #ff5961b4 
    // color: #6b2226 
    font-weight: bold
    color: #a0181f
    font-kerning: none
    word-spacing: -4px

// .review-popup
    // span
        // @include resp-font(.25vw, .85rem)
        // font-family: 'Noto Sans Display', sans-serif

.review-text-lg
    @include resp-font(.25vw, .85rem)

.review-text-label
    @include resp-font(.25vw, .75rem)

.review-job-row-header
    border-bottom: 2px solid var(--text-color)
    font-size: 1.2rem
    font-weight: 500

.review-content
    box-shadow: 0px 1px 19px 5px #5b645934

.review-popup-header
    @include resp-font(.65vw, .8rem)
    font-weight: 700
    font-family: var(--primary-header-font)

    h6
        display: inline
        margin-left: 5px
        @include resp-font(.5vw, .65rem)

.review-page-header
    background-color: var(--surface-100) !important
    border-bottom-left-radius: 0 !important
    border-bottom-right-radius: 0 !important
    height: auto
    padding: 5px !important
    font-weight: 700
    font-family: var(--primary-header-font)
    @include resp-font(.65vw, .8rem)

.review-job-row:nth-child(even)
    background: var(--surface-200)
.review-job-row:nth-child(odd)
    background: var(--surface-50)

.review-unstyled-list
    list-style-type: none
    margin: 0
    padding: 0

.p-tooltip
    .p-tooltip-text
        box-shadow: none !important

// .truncate 
//     // white-space: nowrap
//     overflow: hidden
//     text-overflow: ellipsis

// @use "./_variables"
// @import tools
// :root
//     --content-width: 1050px
//     --border-style-color: var(--border-color)

// #landing-page
//     max-width: var(--content-width)
//     margin: auto
//     .p-datatable
//         margin: auto

// .loader
//     position: fixed
//     z-index: 9999
//     left: 0
//     top: 0
//     width: 100%
//     height: 100%
//     background: rgb(3,59,11)
//     background: radial-gradient(circle, rgba(46, 57, 94, 0.866) 0%, rgba(59, 71, 97, 0.46) 49%, rgba(0,0,0,0.14889705882352944) 100%)

// #price-table
//     margin: 5px 0
//     border: 2px solid var(--border-style-color)
//     .job-text-cell
//         margin: 0
//         padding: 0 0 0 2%
//     .job-text
//         margin: 0
//         padding: 0
//         font-weight: 600
//         border-right: 2px solid var(--border-style-color)
//     .job-info-text
//         font-weight: 300
//         font-size: .85rem
//     .row-legend
//         font-weight: 600
//         font-size: .85rem
//         align-items: center
//         padding: 3% 0
//         div
//             padding: 2%
//     .cell-info
//         font-weight: 300
//         font-size: .85rem

//     .price-table-row:nth-child(even)
//         background: var(--surface-200)
//         filter: brightness(110%)
//     .price-table-row:nth-child(odd)
//         background: var(--surface-300)
//         filter: brightness(110%)

//     .price-table-row
//         // max-height: 10rem
//         margin: auto
//         border-bottom: 2px solid var(--border-style-color)
//         .price-row-cell
//             padding: .35rem 0 0 .5rem
//         .value-cell
//             .upper-cell
//                 border-bottom: 1px solid var(--border-style-color)

//     .price-table-row:last-child
//         border-bottom: 0

// #price-table-header
//     margin: auto
//     font-weight: 600
//     color: var(--brand-text-color-light)
//     background: var(--primary-darken)

// #ncto
//     max-width: var(--content-width)
//     .p-panel-header

//         background: var(--surface-400)
//         // background: linear-gradient(45deg, #4ED03B 0%, hsla(105, 72%, 31%, 1) 100%)
//     .p-panel-title
//         color: var(--text-color)
//     // .p-highlight
//     //     background: var(--primary-darken)
//         // background: linear-gradient(141deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 78.77%, var(--gradient-color-3) 100%)
//     .p-inputwrapper-focus
//         // background: var(--primary-bg)
//         // background: var(--primary-darken)
//         background: var(--surface-400)
//         // text-color: #fff
//         // background: linear-gradient(141deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 100% )

//     .p-autocomplete
//         // background: var(--primary-darken)
//         &.p-button
//             background: var(--primary-darken)

//     .p-autocomplete-item
//         max-height: 25px

//     .row-item:nth-child(even)
//         background: var(--surface-200)
//     .row-item:nth-child(odd)
//         background: var(--surface-300)
//     .job-select-card
//         background: var(--surface-100)

// #job-selection
//     #calendar
//         width: 17%
//     #estimate
//         width: 16%
//     .job-name
//         line-height: 1

// .review-job-row-header
//     border-bottom: 2px solid var(--text-color)
//     font-size: 1.2rem
//     font-weight: 500

// .unstyled-list
//     list-style-type: none
//     margin: 0
//     padding: 0

// .review-job-row:nth-child(even)
//     background: var(--surface-200)
// .review-job-row:nth-child(odd)
//     background: var(--surface-100)

// .action-buttons
//     margin: 0 3px
//     &.p-button-icon-only
//         padding: 0.2rem 0

// .mx-width-pane
//     max-width: 1000px

// .small-info-text
//     font-weight: 400
//     @include resp-font(.25rem, .75rem)

// .clear-borders
//     border: none

// // .truncate 
// //     // white-space: nowrap
// //     overflow: hidden
// //     text-overflow: ellipsis
