@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap");
:root {
  --primary-base: #6eba4e;
  --primary-darken: rgb(0, 91, 114);
  --primary-darkest: rgb(0,61,76);
  --primary-lighter: #6dd849;
  --brand-text-color: #63ab4a;
  --brand-text-color-light: #a0c295;
  --brand-text-hover: lighten(var(--brand-text-color), 20%);
  --border-color: #3e4c5a;
  --gradient-color-1: var(--primary-darkest);
  --gradient-color-2: rgba(66,142,75,1);
  --gradient-color-3: rgb(73, 193, 84);
  font-family: Lato, Helvetica, sans-serif;
  --font-family: Lato, Helvetica, sans-serif;
  --surface-a: #fefefe;
  --surface-b: #6cc24a;
  --surface-c: #003d4c;
  --surface-d: #001516;
  --surface-e: #ffffff;
  --surface-f: #f2682a;
  --text-color: rgb(4, 61, 117);
  --text-color-secondary: #708da9;
  --text-color-light-primary: #7daedb;
  --primary-color: #efefef;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #f1f4f6;
  --surface-100: #e3eee5;
  --surface-200: #dbe6dd;
  --surface-300: #c9dfcd;
  --surface-400: #b0d4b9;
  --surface-500: #80b88e;
  --surface-600: #75a380;
  --surface-700: #435565;
  --surface-800: #2d3844;
  --surface-900: #161c22;
  --gray-50: #f1f4f6;
  --gray-100: #e2e8ee;
  --gray-200: #c6d1dd;
  --gray-300: #a9bbcb;
  --gray-400: #8da4ba;
  --gray-500: #708da9;
  --gray-600: #5a7187;
  --gray-700: #435565;
  --gray-800: #2d3844;
  --gray-900: #161c22;
  --green-500: #6dd849;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 1px #c6b9fd;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #dfdcfc;
  --highlight-text-color: #7254f3;
  --primary-text-font: "Noto Sans Display", sans-serif;
  --primary-header-font: "Gabarito", sans-serif;
}

.data-view-table {
  max-width: 100vw;
  min-width: 100%;
}

.p-datatable {
  max-width: 98%;
  border: 1px solid #3e4c5a;
  margin: auto;
}

.p-datatable-tbody {
  font-size: calc(0.65em + 0.15vw);
}

.client-column-filter {
  flex-wrap: wrap;
}

.p-sortable-column {
  padding: 0 2px;
}

.p-column-title {
  width: 100%;
}

.p-row-odd {
  background-color: rgba(97, 124, 152, 0.1843137255);
}

.p-multiselect-trigger {
  width: 30px;
}

.p-multiselect-panel {
  max-width: 350px;
}

.p-multiselect-header {
  padding: 1%;
  height: 35px;
}

.p-multiselect-items {
  padding-left: 0;
}

.p-multiselect-item {
  padding: 0.25em;
}

.p-multiselect-filter {
  padding: 0;
}

.column {
  padding: 0.5%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.xl-column {
  min-width: 300px;
}

.lg-column {
  max-width: 225px;
}

.md-column {
  max-width: 175px;
}

.sm-column {
  max-width: 125px;
  margin: auto;
}

.column-filter {
  max-height: 40px;
  min-width: 100%;
}

.p-multiselect-label {
  padding-bottom: 0;
  padding-top: 0;
  padding-right: 0;
}

.p-multiselect-token {
  padding-top: 0;
  padding-bottom: 0;
}

.unpaid-ar .rdt_TableHeader {
  text-align: center;
}
.unpaid-ar .rdt_Table {
  width: 95vw;
  max-width: 1000px;
}/*# sourceMappingURL=ar-tables.css.map */