@use "./_variables"
:root 
    --primary-bg: var(--primary-dark)
    // --primary-bg:rgb(13, 110, 253)
    // --brand-text-color: #f6fff7
    // --gradient-color-1: var(--primary-bg)
    // --gradient-color-2: #0e67db
    // --gradient-color-3: #0f8ef7

    color-scheme: light

.primary-bg
    background: var(--primary-color)

.brand-text
    // color: var(--brand-text-color) !important
    color: white !important

.no-dec
    text-decoration: none

.brand-gradient
    background: var(--primary-bg)
    background: linear-gradient(141deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 78.77%, var(--gradient-color-3) 100%) 