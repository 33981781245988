@use "./_variables"
@import tools

// @import theme

// $header-text-shadow: 

.static-metric-panel
    background: tint-color(teal, 85%)
    // background-color: $primary-bg-subtle
    width: 98vw
    //max-width: 960px
    height: 100%
    //max-height: 35vh
    border-radius: 5px
    // border: 1px solid dark-gray
    // filter: drop-shadow(grey 2px 2px 15px)
    margin: 1vh

    h5
        text-shadow: var(--gray-500) 2px 2px 5px
        color: white
        background: radial-gradient(circle, var(--blue-900) 0%, var(--blue-400) 100%)
        border-radius: 5px 5px 0 0
        padding: 1%
        @include resp-font(.9vw, .5em)

    #data-area
        //max-width: 550px
        margin: auto
        display: flex
        flex-wrap: wrap
        justify-content: center

    #dollar-metrics
        padding: 2%

    #static-ar-cards
        width: 100%
        display: flex
        flex-flow: row wrap
        justify-content: space-between
        padding-left: 2em
        padding-right: 2em
        .card-title
            @include resp-font(.3vw, .7em)
        .card-value
            @include resp-font(.45vw, .75em)

    #static-ar-chart
        filter: drop-shadow(var(--gray-500) 2px 2px 2px)
        // width: 100%

.chart-bubble-tag
    margin-right: 2px
    display: inline-block
    width: 15px
    height: 15px
    border-radius: 50% / 50%

.content-area
    gap: 5%
    display: flex
    flex-wrap: wrap
    justify-content: center

.unpaid-ar-pi-chart
    margin: 5%    
